
.table-container[data-v-d7dbdf22] {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
.button-container[data-v-d7dbdf22] {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
}
.add-bank-holiday-modal-button[data-v-d7dbdf22] {
    margin-right: 16px;
    margin-bottom: 16px;
}
.scrollable-table-container[data-v-d7dbdf22] {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;
}
.bank-holiday-modal[data-v-d7dbdf22] {
    min-width: 600px;
    max-width: 500px;
    padding: 20px;
}
.modal-content label[data-v-d7dbdf22] {
    border: none;
    background: none;
    padding: 0;
}
.modal-buttons[data-v-d7dbdf22] {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}
.value-mapping-error-modal-button[data-v-d7dbdf22] {
    display: center;
    justify-content: flex-end;
}
.ui5-label[data-v-d7dbdf22] {
    border: none;
    background: none;
    padding: 0;
    margin-bottom: 5px;
    display: inline-block;
}
input.datepicker[data-v-d7dbdf22] {
    padding: 5px;
    width: 50%;
    margin-top: 5px;
}
input.select[data-v-d7dbdf22] {
    padding: 5px;
    width: 50%;
}
.time-type-modal[data-v-d7dbdf22] {
    min-width: 600px;
    max-width: 500px;
    padding: 20px;
}
.value-mapping-modal[data-v-d7dbdf22] {
    min-width: 600px;
    max-width: 500px;
    padding: 20px;
}
.time-type-delete-error-modal[data-v-d7dbdf22] {
    min-width: 600px;
    max-width: 500px;
    padding: 20px;
}
.modal-content[data-v-d7dbdf22] {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.modal-input[data-v-d7dbdf22] {
    width: 100%;
    margin-bottom: 15px;
    margin-left: -1px;
}
.modal-input-error[data-v-d7dbdf22] {
    width: 100%;
    margin-bottom: 15px;
    margin-left: -1px;
    border: 1px solid red;
}
.modal-select[data-v-d7dbdf22] {
    width: 100%;
    margin-bottom: 15px;
}
.dbt-select-label[data-v-d7dbdf22] {
    margin-right: 20px;
}
.table-delete-button[data-v-d7dbdf22] {
    margin-top: 5px;
}


#time-report-table[data-v-68d78687] {
  width: 100%;
  border-collapse: collapse;
}
ui5-table-column[data-v-68d78687] {
  text-align: left;
}
.hour-cell[data-v-68d78687] {
  text-align: right;
  padding: 8px;
  background-color: #f9f9f9;
  font-weight: bold;
}
ui5-table-cell[data-v-68d78687]:first-child {
  font-weight: bold;
  background-color: #e0e0e0;
  padding: 10px;
}
ui5-table-cell[data-v-68d78687] {
    text-align: center;
}
.ui5-table-column-label[data-v-68d78687] {
    text-align: center; 
    display: block;    
    width: 100%;
}



#logo[data-v-5b8d9444] {
  width: 100px;
}
#navbar[data-v-5b8d9444] {
  height: 40px;
  background: #354a5f;
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
}
#nav-header[data-v-5b8d9444] {
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
#nav-environment-text[data-v-5b8d9444] {
  color: #fff;
  display: block;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  pointer-events: none;
}
#nav-env-container[data-v-5b8d9444] {
  height: 100%;
  padding-bottom: 1rem;
}
#nav-links ui5-button[data-v-5b8d9444] {
  height: 32px;
  color: #fff !important;
  padding-left: 8px;
  padding-right: 8px;
  transition: 0.4s ease;
  background: #354a5f;
  border: none;
}
#nav-environment-text[data-v-5b8d9444] {
  color: #fff;
  display: block;
  font-weight: 400;
  margin: 1px 0;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  pointer-events: none;
  padding-bottom: 1rem;
}
#nav-links ui5-button[data-v-5b8d9444]:hover,
#nav-links ui5-button[data-v-5b8d9444]:active,
#nav-links ui5-button.active[data-v-5b8d9444] {
  background: #283848;
}

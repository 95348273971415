
.wrapper[data-v-67e21bbe] {
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.form-group[data-v-67e21bbe] {
  float: left;
  display: block;
}
.filter-input[data-v-67e21bbe] {
  display: table-cell;
  width: 6rem;
  vertical-align: middle;
}
.label[data-v-67e21bbe] {
  text-align: left;
  padding-right: 0.5rem;
}
.filter-buttons[data-v-67e21bbe] {
  width: 100%;
  text-align: center;
  padding: 0rem 0.25rem 0.5rem 0.25rem;
}
.filter-button[data-v-67e21bbe] {
  width: 45%;
}

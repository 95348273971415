
.report-row[data-v-381cd559] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.report-text[data-v-381cd559] {
  flex-grow: 1;
  padding-right: 15px;
}
.report-button[data-v-381cd559] {
  flex-shrink: 0;
}

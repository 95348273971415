
#case-states-table[data-v-4105b2f6] {
  max-height: calc(100vh - 220px);
  overflow-x: scroll;
  overflow-y: scroll;
}
#case-states-table ui5-table-cell > *[data-v-4105b2f6] {
  vertical-align: middle;
}
.small-column[data-v-4105b2f6] {
  width: 120px;
}

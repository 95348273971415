
.nav-button[data-v-0c2d7965] {
  width: 6rem;
}

/* Make circles that indicate the steps of the form: */
.step[data-v-0c2d7965] {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

/* Mark the active step: */
.step.active[data-v-0c2d7965] {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish[data-v-0c2d7965] {
  background-color: #4caf50;
}

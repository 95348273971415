
.section[data-v-5ae3dcac] {
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 0px;
}
.wrapper[data-v-5ae3dcac] {
  padding: 0.1rem 0;
}
#selected-items-container[data-v-5ae3dcac] {
  border: 1px solid #89919a;
  border-radius: 2px;
  padding: 8px;
  min-height: 34px;
}
.selected-item[data-v-5ae3dcac] {
  border: 1px solid #89919a;
  background: #dee6ed;
  color: #354a5f;
  border-radius: 40px;
  padding: 4px 4px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  max-height: 20px;
  margin: 2px;
  overflow: hidden;
}
.item-content[data-v-5ae3dcac] {
  margin-left: 8px;
}
.item-btn[data-v-5ae3dcac] {
  color: #354a5f;
  margin-left: 8px;
  font-size: 16px;
  width: 18px;
  height: 18px;
  border: none !important;
  outline: none !important;
  transition: 0.1s ease;
}
.item-btn[data-v-5ae3dcac]:hover {
  transform: scale(1.09);
}
.actions[data-v-5ae3dcac] {
  line-height: 1.5;
  font-size: 14px;
  padding: 10px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.actions ui5-button[data-v-5ae3dcac] {
  padding: 4px 0px 0px 0px;
  height: 18px;
  border: none;
}
.option-input[data-v-5ae3dcac] {
  display: block;
  width: 100%;
}
.label[data-v-5ae3dcac] {
  text-align: left;
  padding-bottom: 0.5rem;
}
.add-options-btn[data-v-5ae3dcac] {
  margin: 15px 0px 15px 15px;
  float: right;
}
